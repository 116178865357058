import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Reorder } from 'framer-motion';
import Button from '@mui/material/Button';
import { v4 as uuidv4 } from 'uuid';

import {
  addPoint,
  updatePoint,
  setRoutePoints,
} from '../../../../../../store/slices/orderSlice';
import { Points } from '../index';

export const ItemPoints = ({ isLoaded }) => {
  const dispatch = useDispatch();
  const components = useSelector((state) => state.order.routePoints);

  const onSelect = useCallback(
    (location, description, id) => {
      dispatch(updatePoint({ id, description, location }));
    },
    [dispatch],
  );

  const addInput = useCallback(() => {
    // eslint-disable-next-line
    dispatch(
      addPoint({ id: uuidv4(), description: '', location: {}, isClick: false }),
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(setRoutePoints(components));
  }, [components, dispatch]);

  return (
    <div>
      <Reorder.Group
        axis="y"
        values={components}
        onReorder={(newComponents) => dispatch(setRoutePoints(newComponents))}
      >
        {components.map((component, index) => (
          <Points
            key={component.id}
            component={component}
            index={index}
            value={component.description}
            id={component.id}
            onSelect={onSelect}
            isLoaded={isLoaded}
            placeholder={
              index === 0
                ? 'Стартова точка'
                : index + 1 === components.length
                  ? 'Кінечна точка'
                  : 'Проміжуточна точка'
            }
          />
        ))}
      </Reorder.Group>
      <Button sx={{ mt: 1 }} variant="contained" fullWidth onClick={addInput}>
        Додати точку
      </Button>
    </div>
  );
};
