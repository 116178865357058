import React, { useCallback, useState, memo, useEffect } from "react";
import {
  DirectionsRenderer,
  DirectionsService,
  GoogleMap,
  Marker,
  Polygon,
  InfoWindow,
} from "@react-google-maps/api";
import { useSelector, useDispatch } from "react-redux";
import { pathKyiv } from "../../../../../paths";
import { useGetPriceZoneQuery } from "../../../../../store/api";
import { CurrentLocationMarker } from "../CurrentLocationMarker";
import { addPoint } from "../../../../../store/slices/orderSlice";
import { v4 as uuidv4 } from "uuid";
import { Button } from "@mui/material";

const containerStyle = {
  width: "100%",
  height: "93vh",
};

const defaultOptions = {
  panControl: true,
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  clickableIcons: false,
  keyboardShortcuts: false,
  scrollwheel: true,
  disableDoubleClickZoom: false,
  fullscreenControl: false,
};

export const Map = memo(
  ({
     center,
     isLoaded,
     markers,
     originPlace,
     destinationPlace,
     waypoints,
     onRoute,
   }) => {
    waypoints &&
    waypoints.forEach((v) => {
      delete v.isClick;
    });

    const dispatch = useDispatch();
    const [response, setResponse] = useState(null);
    const [hasRequestedRoute, setHasRequestedRoute] = useState(false);
    const [clickedLocation, setClickedLocation] = useState(null);
    const [address, setAddress] = useState("");
    const [currentLocation, setCurrentLocation] = useState({});
    const [infoWindowOpen, setInfoWindowOpen] = useState(false);
    const zones = useSelector((state) => state.zone.zones);
    const isZone = useSelector(
      (state) => state.dispatcher.dispatcherData.zoneVisible,
    );
    useGetPriceZoneQuery();

    const directionsCallback = useCallback(
      (response) => {
        if (response !== null) {
          if (response.status === "OK") {
            setHasRequestedRoute(true);
            let sum = 0;
            let time = 0;
            let kmTotal = 0;

            const polygon = new window.google.maps.Polygon({ paths: pathKyiv });

            for (let i = 1; i < response.routes[0].overview_path.length; i++) {
              const prevPoint = response.routes[0].overview_path[i - 1];
              const currPoint = response.routes[0].overview_path[i];

              if (
                window.google.maps.geometry.poly.containsLocation(
                  currPoint,
                  polygon,
                )
              ) {
                const distance =
                  window.google.maps.geometry.spherical.computeDistanceBetween(
                    new window.google.maps.LatLng(prevPoint),
                    new window.google.maps.LatLng(currPoint),
                  );
                kmTotal += distance / 1000;
              }
            }
            response.routes[0].legs.forEach((i) => {
              time += i.duration.value / 60;
              sum += parseFloat(i.distance.text.replace(",", "."));
            });
            onRoute(sum, Math.ceil(time), parseFloat(kmTotal.toFixed(1)));
            setResponse(response);
          }
        }
      },
      [onRoute, response],
    );

    const getAddressFromCoordinates = (lat, lng, callback) => {
      const geocoder = new window.google.maps.Geocoder();
      const latLng = new window.google.maps.LatLng(lat, lng);

      geocoder.geocode(
        { location: latLng, language: "uk" },
        (results, status) => {
          if (status === "OK") {
            if (results[0]) {
              callback(results[0].formatted_address);
            } else {
              console.log("No results found");
            }
          } else {
            console.log("Geocoder failed due to: " + status);
          }
        },
      );
    };

    const handleMapClick = useCallback((event) => {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();

      getAddressFromCoordinates(lat, lng, (address) => {
        setClickedLocation({ lat, lng });
        setCurrentLocation({
          id: uuidv4(),
          description: address,
          location: { lat, lng },
          isClick: true,
        });
        setAddress(address);
        setInfoWindowOpen(true); // Open InfoWindow on click
      });
    }, []);

    const acceptLocation = () => {
      dispatch(addPoint(currentLocation));
      setCurrentLocation({});
      setInfoWindowOpen(false); // Close InfoWindow after accepting location
    };

    useEffect(() => {
      setHasRequestedRoute(false);
    }, [originPlace, destinationPlace, waypoints]);

    return (
      <>
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={12}
            options={defaultOptions}
            onClick={handleMapClick}
          >
            {zones.map((i) => (
              <Polygon
                key={i._id}
                paths={i.coordinates}
                options={{
                  fillColor: isZone ? "transparent" : "none",
                  fillOpacity: isZone ? 1 : 0,
                  strokeColor: isZone ? "red" : "none",
                  strokeOpacity: isZone ? 1 : 0,
                  strokeWeight: isZone ? 2 : 0,
                  clickable: isZone,
                  draggable: false,
                  editable: false,
                  geodesic: false,
                  zIndex: 1,
                }}
                onClick={handleMapClick}
              />
            ))}

            <Polygon
              paths={pathKyiv}
              options={{
                fillColor: isZone ? "transparent" : "none",
                fillOpacity: isZone ? 1 : 0,
                strokeColor: isZone ? "green" : "none",
                strokeOpacity: isZone ? 1 : 0,
                strokeWeight: isZone ? 6 : 0,
                clickable: isZone,
                draggable: false,
                editable: false,
                geodesic: false,
                zIndex: 1,
              }}
              onClick={handleMapClick}
            />

            <CurrentLocationMarker center={center} />
            {markers.map((position) => (
              <Marker key={position.toString()} position={position} />
            ))}
            {originPlace !== "" &&
              destinationPlace !== "" &&
              !hasRequestedRoute && (
                <DirectionsService
                  options={{
                    destination: destinationPlace,
                    origin: originPlace,
                    travelMode: "DRIVING",
                    waypoints,
                    drivingOptions: {
                      departureTime: new Date(),
                      trafficModel: "bestguess",
                    },
                    provideRouteAlternatives: true,
                  }}
                  callback={directionsCallback}
                />
              )}

            {originPlace !== "" &&
              destinationPlace !== "" &&
              response !== null && (
                <DirectionsRenderer
                  options={{
                    polylineOptions: {
                      fillOpacity: 0.2,
                      strokeColor: "blue",
                      strokeOpacity: 0.6,
                      strokeWeight: 4,
                    },
                    directions: response,
                  }}
                />
              )}

            {clickedLocation && infoWindowOpen && (
              <Marker position={clickedLocation}>
                <InfoWindow
                  position={clickedLocation}
                  onCloseClick={() => setInfoWindowOpen(false)}
                >
                  <div>
                    <div>{address}</div>
                    <div className={'flex'}>
                      <Button onClick={acceptLocation}>
                        Додати
                      </Button>
                    </div>
                  </div>
                </InfoWindow>
              </Marker>
            )}
          </GoogleMap>
        ) : (
          <div>Loading...</div>
        )}
      </>
    );
  },
);
