import React, { useEffect, useState } from 'react';
import {
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Table,
  Box,
  CircularProgress,
  IconButton,
  TablePagination,
  TextField,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Edit } from '@mui/icons-material';

import { useGetOrdersQuery } from '../../../store/api';
import { getColorForStatus, stageOrder, statusOrder } from './utils';

export const OrderListPage = () => {
  const navigate = useNavigate();

  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [driverNickname, setDriverNickname] = useState('');
  const [clientPhoneNumber, setClientPhoneNumber] = useState('');

  const { data, isLoading } = useGetOrdersQuery({
    page,
    limit: rowsPerPage,
    driverNickname,
    clientPhoneNumber,
  });

  const handlePageChange = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
  };

  const handleRowClick = (rowId) => {
    navigate(`/dispatcher/order/${rowId}`);
  };

  const translateValue = (dictionary, value) => {
    return dictionary[value] || value;
  };

  const handleDriverNicknameChange = (event) => {
    setDriverNickname(event.target.value);
  };

  const handleClientPhoneNumberChange = (event) => {
    setClientPhoneNumber(event.target.value);
  };

  const calculateTimeElapsed = (creationDate) => {
    const now = new Date();
    const createdTime = new Date(creationDate);
    const diffInMs = now - createdTime;
    const diffInMinutes = Math.floor(diffInMs / (1000 * 60));

    if (diffInMinutes < 60) {
      return `${diffInMinutes} хвилин тому`;
    }
    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) {
      return `${diffInHours} годин тому`;
    }
    const diffInDays = Math.floor(diffInHours / 24);
    return `${diffInDays} днів тому`;
  };

  useEffect(() => {
    if (!isLoading && data) {
      setOrders(data.data);
    }
  }, [isLoading, data]);

  console.log('orders', orders);

  return (
    <Box>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box sx={{ margin: 3 }}>
            <TextField
              sx={{ marginRight: 5 }}
              label="Позивний"
              value={driverNickname}
              onChange={handleDriverNicknameChange}
            />
            <TextField
              label="Номер телефона клієнта"
              value={clientPhoneNumber}
              onChange={handleClientPhoneNumberChange}
            />
          </Box>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Водій</TableCell>
                  <TableCell>Етап</TableCell>
                  <TableCell>Статус</TableCell>
                  <TableCell>Номер клієнта</TableCell>
                  <TableCell>Початковий адрес</TableCell>
                  <TableCell>Ціна</TableCell>
                  <TableCell>Час з моменту створення</TableCell>
                  <TableCell>Редагувати</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      Замовлень немає
                    </TableCell>
                  </TableRow>
                ) : (
                  orders.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{ fontWeight: 600, fontSize: 16 }}>
                        {row?.nicknameDriver || 'Немає'}
                      </TableCell>
                      <TableCell>
                        {translateValue(stageOrder, row?.stage)}
                      </TableCell>
                      <TableCell
                        style={{
                          background: getColorForStatus(row?.status),
                          color: 'white',
                          fontWeight: 600,
                        }}
                      >
                        {translateValue(statusOrder, row?.status)}
                      </TableCell>
                      <TableCell>{row?.clientPhoneNumber}</TableCell>
                      <TableCell>{row?.locationRoute[0]?.name}</TableCell>
                      <TableCell>{row?.price}</TableCell>
                      <TableCell>
                        {row?.creationDate && row?.stage === 'notTaken' && !row?.driverId && row.status === 'pending'
                          ? calculateTimeElapsed(row?.creationDate)
                          : '-'}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => handleRowClick(row?._id)}
                          color="primary"
                          aria-label="edit row"
                        >
                          <Edit />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={data?.totalItems || 0}
            page={page - 1}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </Box>
  );
};
